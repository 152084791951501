import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { useMedia } from 'react-use'
import { Container, Divider, Grid, Image, SearchProps } from 'semantic-ui-react'

import HeaderBasic from 'components/app/project/header/header-basic'
import * as SelectionUtils from 'components/app/project/room/selection/utils'
import NobiliaSampleClassSelectionCard from 'components/shared/catalog/nobilia-sample-class/card'
import { getChipColor } from 'components/shared/chip'
import { notifyError } from 'context/notifications/trigger'
import useOrderMutateNoContext from 'context/order/use-mutate-no-context'
import * as checkoutUtils from 'context/order/utils-checkout'
import useProjectOrderSamplesMany from 'context/project/order-samples/use-many'
import useGuideUrls from 'context/project/use-guide-urls'
import useRoom from 'context/room/use'
import Button from 'design-system/components/button'
import SelectionLayout from 'design-system/containers/selection-layout'
import { Breakpoint } from 'styles/app/system'
import { NobiliaSampleClass } from 'types/nobilia-sample-class'
import { OrderType } from 'types/order'
import { ProductClassType } from 'types/product-class'
import { RoomGraphQL } from 'types/room'

const sampleCategory = [
  'Matt',
  'Gloss',
  'Shaker',
  'WoodReproduction',
  'NaturalReproduction',
  'All',
]

const SESSION_STORAGE_CATEGORY_KEY = 'samplesCategorySelected'
const SESSION_STORAGE_SAMPLES_KEY = 'samplesSelected'

type PickerProps = RouteComponentProps<{
  project_id: string
  order_id: string
  location: {
    state: {
      fromDesignCall?: boolean
      fromSamplesView?: boolean
    }
  }
}>

const Picker = (props: PickerProps) => {
  const isMobile = useMedia(Breakpoint.onlyMobile)
  const { fromDesignCall, fromSamplesView } = props.location?.state || {}
  const {
    orders,
    refetch,
    samplesMattPriceGroup1,
    samplesMattPriceGroup2,
    samplesSupermattPriceGroup3,
    samplesMattEasyTouchPriceGroup4,
    samplesMattHonedPriceGroup3,
    samplesMattHonedPriceGroup4,
    samplesMattHonedPriceGroup5,
    samplesHighGlossPriceGroup3,
    samplesUltraHighGlossPriceGroup4,
    samplesHighGlossLacqueredPriceGroup9,
    samplesWoodReproductionPriceGroup2,
    samplesWoodReproductionPriceGroup3,
    samplesWoodReproductionPriceGroup4,
    samplesNaturalReproductionPriceGroup1,
    samplesNaturalReproductionPriceGroup2,
    samplesNaturalReproductionPriceGroup4,
    samplesNaturalReproductionPriceGroup10,
    samplesShakerPriceGroup7,
    samplesShakerPriceGroup8,
    samplesShakerPriceGroup9,
    samplesAll,
  } = useProjectOrderSamplesMany()
  const { createOrder, updateOrder } = useOrderMutateNoContext()
  const { DesignCall } = useGuideUrls()

  const order = orders.find((o) => o.id === props.order_id)

  //pre-populate samples
  const { fronts, room } = useRoom()
  const frontsOptKey = fronts?.map((f) => f.optionKey)
  const samplesFromDesignBrief = samplesAll
    .filter((s) => frontsOptKey?.includes(s.identifier))
    .filter((s) => !s.meta.outOfStock)

  const initialSamples = !orders.length
    ? samplesFromDesignBrief.slice(0, 3) || []
    : order?.nobiliaSamples?.map((s) => s.productClass) || []

  // So selections persist when user clicks expand on a sample card
  const [selection, setSelection] = useState<NobiliaSampleClass[]>(() => {
    const savedValue = sessionStorage.getItem(SESSION_STORAGE_SAMPLES_KEY)
    sessionStorage.removeItem(SESSION_STORAGE_SAMPLES_KEY)
    return savedValue ? JSON.parse(savedValue) : initialSamples
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [search, setSearch] = useState<{
    results: NobiliaSampleClass[] | null
    value: string
  }>({
    results: null,
    value: '',
  })

  const onBack = () => {
    if (fromSamplesView)
      return navigate(`/app/projects/${props.project_id}/sample-orders`, {
        state: props.location?.state,
      })
    if (fromDesignCall) return navigate(DesignCall)
    return navigate(`/app/projects/${props.project_id}`)
  }

  const [category, setCategory] = useState<string | null>(() => {
    const savedValue = sessionStorage.getItem(SESSION_STORAGE_CATEGORY_KEY)
    sessionStorage.removeItem(SESSION_STORAGE_CATEGORY_KEY)
    return savedValue || 'Matt'
  })

  const SamplesPerCategory: Record<
    string,
    {
      title: string
      mobileTitle?: string
      groups: {
        samples: NobiliaSampleClass[]
        name?: string
      }[]
    }
  > = {
    Matt: {
      title: 'Matt',
      groups: [
        {
          samples: [...samplesMattPriceGroup1, ...samplesMattPriceGroup2],
          name: 'Matt',
        },
        {
          samples: samplesSupermattPriceGroup3,
          name: 'Supermatt',
        },
        {
          samples: samplesMattEasyTouchPriceGroup4,
          name: 'Easytouch',
        },
        {
          samples: [
            ...samplesMattHonedPriceGroup3,
            ...samplesMattHonedPriceGroup4,
            ...samplesMattHonedPriceGroup5,
          ],
          name: 'Honed',
        },
      ],
    },
    Gloss: {
      title: 'Gloss',
      groups: [
        {
          samples: samplesHighGlossPriceGroup3,
          name: 'Gloss',
        },
        {
          samples: samplesUltraHighGlossPriceGroup4,
          name: 'Ultra High Gloss',
        },
        {
          samples: samplesHighGlossLacqueredPriceGroup9,
          name: 'High Gloss Lacquered Laminate',
        },
      ],
    },
    Shaker: {
      title: 'Shaker',
      groups: [
        {
          samples: [
            ...samplesShakerPriceGroup7,
            ...samplesShakerPriceGroup8,
            ...samplesShakerPriceGroup9,
          ],
        },
      ],
    },
    WoodReproduction: {
      title: 'Wood Reproduction',
      mobileTitle: 'Wood Rep',
      groups: [
        {
          samples: [
            ...samplesWoodReproductionPriceGroup2,
            ...samplesWoodReproductionPriceGroup3,
            ...samplesWoodReproductionPriceGroup4,
          ],
        },
      ],
    },
    NaturalReproduction: {
      title: 'Natural Reproduction',
      mobileTitle: 'Natural Rep',
      groups: [
        {
          samples: [
            ...samplesNaturalReproductionPriceGroup1,
            ...samplesNaturalReproductionPriceGroup2,
            ...samplesNaturalReproductionPriceGroup4,
            ...samplesNaturalReproductionPriceGroup10,
          ],
        },
      ],
    },
    All: {
      title: 'All',
      groups: [{ samples: samplesAll }],
    },
  }

  const onSelect = (sample: NobiliaSampleClass) => {
    if (selection.find((s) => s.id === sample.id))
      setSelection(selection.filter((s) => s.id !== sample.id))
    else {
      if (selection.length >= 3)
        notifyError('Remove a sample to choose a new one')
      else setSelection([...selection, sample])
    }
  }

  const onSave = async () => {
    setLoading(true)

    if (order?.id) {
      await updateOrder({
        variables: {
          data: {
            nobiliaSamples: {
              upsert: selection.map((ns) => ({
                where: {
                  id: ns.id || 'new',
                },
                update: {
                  quantity: 1,
                  productClassType: ns.productClassType,
                  productClass: { connect: { id: ns.id } },
                },
                create: {
                  quantity: 1,
                  productClassType: ns.productClassType,
                  productClass: { connect: { id: ns.id } },
                },
              })),
              delete: order.nobiliaSamples
                ?.filter(
                  (ns) =>
                    !selection.map((ns) => ns.id).includes(ns.id as string),
                )
                .map((ns) => ({ id: ns.id as string })),
            },
          },
          where: { id: order.id },
        },
      })
    } else
      await createOrder({
        variables: {
          data: {
            discounts: orders.length
              ? []
              : [
                  {
                    name: '3 Samples Included',
                    quantity: 3,
                    price: -checkoutUtils.NOBILIA_SAMPLE_PRICE,
                  },
                ],
            metadata: {
              name: 'Samples Order',
            },
            nobiliaSamples: {
              create: selection.map((s) => ({
                quantity: 1,
                productClassType: ProductClassType.NOBILIA_SAMPLE,
                productClass: {
                  connect: {
                    id: s.id,
                  },
                },
              })),
            },
            project: {
              connect: {
                id: props.project_id as string,
              },
            },
            type: OrderType.SAMPLES,
          },
        },
      })
    setLoading(false)
  }

  const handleSearchChange = (
    _e: React.MouseEvent<HTMLElement, MouseEvent>,
    { value }: SearchProps,
  ) => {
    setCategory(value ? null : 'Matt')
    setSearch({
      results: !value
        ? null
        : samplesAll.filter((f) =>
            [f.identifier, f.description].some(
              (s) => s?.toLowerCase().includes(value.toLowerCase()),
            ),
          ),
      value: value as string,
    })
  }

  return (
    <SelectionLayout
      back={
        isMobile ? (
          <Button
            color="gray"
            fontAwesomeIcon="arrow-left"
            iconAnimation="left"
            iconPosition="left"
            kind="text"
            onClick={onBack}
            text=""
          />
        ) : null
      }
      categories={
        <SelectionUtils.ScrollCategories>
          {sampleCategory.map((c, key) => (
            <SelectionUtils.Category
              key={`sample-category-${c}-${key}`}
              active={category === c}
              disabled={!!search.value}
              onClick={() => setCategory(c)}
              color={getChipColor(undefined, 1).tagColor}
              textColor={getChipColor(undefined).textColor}
            >
              <p>
                {isMobile
                  ? SamplesPerCategory[c].mobileTitle ||
                    SamplesPerCategory[c].title
                  : SamplesPerCategory[c].title}
              </p>
            </SelectionUtils.Category>
          ))}
        </SelectionUtils.ScrollCategories>
      }
      footer={
        <>
          <div className="only-mobile" style={{ height: '500px' }} />
          <SelectionUtils.StyledFooter>
            <Container>
              <Grid>
                <Grid.Row columns="equal" verticalAlign="middle">
                  <Grid.Column
                    className="samples-column"
                    largeScreen={5}
                    computer={6}
                    tablet={16}
                    mobile={16}
                  >
                    {selection.map((s, i) => {
                      const item = checkoutUtils.nobiliaSampleToPaymentItem({
                        quantity: 1,
                        productClassType: ProductClassType.NOBILIA_SAMPLE,
                        productClass: s,
                      })
                      return (
                        <div key={`selection-${s.identifier}-${i}`}>
                          <SelectionUtils.SampleView>
                            <Image src={item.imageUrl} />
                            <p style={{ fontSize: '13px' }}>
                              {item.name.match(/\[(.*?)\]/)?.[0]}
                            </p>
                          </SelectionUtils.SampleView>
                          <SelectionUtils.StyledClose
                            onClick={async (event) => {
                              event.stopPropagation()
                              onSelect(s)
                            }}
                          >
                            <FontAwesomeIcon icon={['fal', 'times']} />
                          </SelectionUtils.StyledClose>
                        </div>
                      )
                    })}
                    {new Array(
                      3 - selection.length > 0 ? 3 - selection.length : 0,
                    )
                      .fill('')
                      .map((_, id) => (
                        <SelectionUtils.SampleView
                          key={`sample-view-${id}`}
                          style={{ paddingBottom: '5px' }}
                        >
                          <div className="pending">
                            <FontAwesomeIcon
                              icon={['fal', 'question-circle']}
                            />
                          </div>
                          <p style={{ fontSize: '13px' }}>--</p>
                        </SelectionUtils.SampleView>
                      ))}
                  </Grid.Column>
                  <Grid.Column
                    largeScreen={7}
                    computer={5}
                    tablet={10}
                    mobile={16}
                  >
                    <p
                      className={isMobile ? 'gray x-small' : 'gray small'}
                      style={{ margin: '6px 0' }}
                    >
                      Our full-size fronts ship in sets of 3 for $99, excluding
                      tax. The first set is included with the Design Package.
                    </p>
                  </Grid.Column>
                  <Grid.Column
                    largeScreen={4}
                    computer={5}
                    tablet={6}
                    mobile={16}
                    textAlign="right"
                  >
                    <Button
                      disabled={selection.length < 3}
                      fontAwesomeIcon="clipboard-list"
                      iconPosition="left"
                      loading={loading}
                      onClick={async () => {
                        await onSave()
                        await refetch()
                        navigate(
                          `/app/projects/${props.project_id}/sample-orders`,
                          { state: props.location?.state },
                        )
                      }}
                      kind="solid"
                      text={order?.id ? 'Update Order' : 'Create Order'}
                      size="large"
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </SelectionUtils.StyledFooter>
        </>
      }
      header={
        <span className="up-mobile">
          <HeaderBasic onBack={onBack} />
        </span>
      }
      searchProps={{
        onClose: () => {
          setCategory('Matt')
          setSearch({
            results: null,
            value: '',
          })
        },
        onSearchChange: handleSearchChange,
        value: search.value,
      }}
      title="Samples"
    >
      <Grid doubling columns={5}>
        <Grid.Row stretched className="row-gap">
          {search.value && !search.results?.length ? (
            <Grid.Column>
              <p>No results</p>
            </Grid.Column>
          ) : search.results ? (
            search.results.map((f, i) =>
              room ? (
                <NobiliaSampleColumn
                  key={`search-${f.id}-${i}`}
                  sample={f}
                  room={room}
                  selection={selection}
                  onSelect={onSelect}
                  category={category as string}
                />
              ) : null,
            )
          ) : (
            SamplesPerCategory[category as string]?.groups.map((g, key) => (
              <React.Fragment key={`samples-per-category-${g.name}-${key}`}>
                {g.name ? (
                  <SelectionUtils.CategoryColumn width={16}>
                    <Divider hidden className="up-mobile" />
                    <p
                      className="caption"
                      style={{
                        borderBottom: '1px dashed #D6D3D1',
                        paddingBottom: '8px',
                      }}
                    >
                      {g.name}
                    </p>
                  </SelectionUtils.CategoryColumn>
                ) : null}
                {g.samples.map((f, i) =>
                  room ? (
                    <NobiliaSampleColumn
                      key={`samples-${f.identifier}-${key}-${i}`}
                      sample={f}
                      room={room}
                      selection={selection}
                      onSelect={onSelect}
                      category={category as string}
                    />
                  ) : null,
                )}
              </React.Fragment>
            ))
          )}
        </Grid.Row>
      </Grid>
    </SelectionLayout>
  )
}

const NobiliaSampleColumn = ({
  sample,
  room,
  selection,
  category,
  onSelect,
}: {
  sample: NobiliaSampleClass
  room: RoomGraphQL
  selection: NobiliaSampleClass[]
  category: string
  onSelect: (sample: NobiliaSampleClass) => void
}) => (
  <Grid.Column>
    <NobiliaSampleClassSelectionCard
      catalog={room?.data?.catalog || process.env.GATSBY_DEFAULT_CATALOG}
      nobiliaSampleClass={sample}
      onExpand={() => {
        // So selections and category persist when user clicks expand on a sample card
        sessionStorage.setItem(
          SESSION_STORAGE_SAMPLES_KEY,
          JSON.stringify(selection),
        )
        sessionStorage.setItem(SESSION_STORAGE_CATEGORY_KEY, category)
      }}
      selectionCardProps={{
        expandAndSelect: true,
        onSelect: () => onSelect(sample),
        selected: selection.some((s) => s.id === sample.id),
        disabled: sample.meta.outOfStock,
      }}
    />
  </Grid.Column>
)

export default Picker
