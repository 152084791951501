import React from 'react'

import { Grid } from 'semantic-ui-react'

import useProject from 'context/project/use'
import NextStepsCarousel from 'design-system/components/guide/next-steps-carousel'
import { Section } from 'design-system/components/guide/utils'

const nextSteps = [
  {
    leadText:
      'After you pay the <span class="bold">$99.00</span>, your designer will start working on the <span class="bold">Project Proposal</span> and send out your <span class="bold">sample order</span>.',
    image: `images/interactive-guides/project-consultation/next-step-project-consultation-01.svg`,
  },
  {
    leadText:
      'Your sample order will include <span class="bold">3 full size door</span> fronts that we ship directly to you.',
    description:
      "If you <span class='bold'>login to your project</span> dashboard, you'll be able to <span class='bold'>select sample finishes</span> and <span class='bold'>track your order</span>.<br/> Additional samples can be purchased in sets of 3 for $99.<br/> We expect your samples to arrive in <span class='bold'>7-10 days.</span>",
    image: `images/interactive-guides/project-consultation/next-step-project-consultation-02.svg`,
  },
  {
    leadText:
      'Your <span class="bold">Project Proposal</span> will include a rendering, floor plan, elevations and pricing.',
    description:
      'We typically cap design work around 6 hours, but will provide a price breakdown for the entire scope of work.',
    image: `images/interactive-guides/project-consultation/next-step-project-consultation-03.svg`,
  },
  {
    leadText:
      'Your designer will <span class="bold">schedule your next call</span> to review the <span class="bold">Project Proposal in detail</span>.',
    description:
      "They'll want to coordinate timing to make sure that you have samples in hand for the review.",
    image: `images/interactive-guides/project-consultation/next-step-project-consultation-04.svg`,
  },
  {
    leadText:
      'Your designer will <span class="bold">share the Proposal</span> over email and publish it on your dashboard so that you can <span class="bold">review it in further detail</span>.',
    description:
      'Any subsequent design revisions can be made in the development phase.',
    image: `images/interactive-guides/project-consultation/next-step-project-consultation-05.svg`,
  },
]

const NextSteps = () => {
  const { isTradeProject } = useProject()

  return (
    <Section id="next-steps">
      <Grid centered>
        <NextStepsCarousel
          isTradeProject={isTradeProject}
          slideData={nextSteps}
        />
      </Grid>
    </Section>
  )
}

export default NextSteps
